.schedule-day:before {
    content: "+";
    display: block;
    /*background: url("icon.jpg") no-repeat;*/
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 6px 0 0;
}

