/*select::-ms-expand {*/
/*    display: none;*/
/*}*/

/*select {*/
/*    -webkit-appearance: none;*/
/*    -moz-appearance: none;*/
/*    appearance: none;*/
/*    padding: 2px 30px 2px 2px;*/
/*    !*border: none; - if you want the border removed*!*/
/*}*/